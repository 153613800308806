import AuthContext from "./AuthContext";
import { useContext } from "react";
import Login from "views/default/Login";
import CryptoJS from "crypto-js";
// import {Redirect} from "react-router-dom";
import {
  Redirect,
  Routes,
  Route,
  NavLink,
  Navigate,
  useNavigate,
} from "react-router-dom";

// const ProtectedRoute = ({ children, accessedBy }) => {
//     const {user} = useContext(AuthContext);
//     console.log(user);
//     if (!user) {
//       return <Redirect to="/login" replace />;
//     }

//     // return children;
//     return (
//         <AuthContext.Provider >
//           {children}
//         </AuthContext.Provider>
//       );
//   };
const ProtectedRoute = ({ children, requiredRoles, privileges }) => {
  const SECRET_KEY =
    "Ahe8Bl10vlPhyq2OGjYgsU9oiHOCB9voQT4WpE7cDAKIey7SPYOJ9DnA25iSBE";
  let isAuthorized = false;
  let privileges1 = "";
  const { user } = useContext(AuthContext);
  if (localStorage.getItem("userProfile")) {
    const bytes = CryptoJS.AES.decrypt(
      localStorage.getItem("userProfile"),
      SECRET_KEY
    );

    let userProfile = bytes.toString(CryptoJS.enc.Utf8);

    if (userProfile != null) {
      const role = JSON.parse(userProfile);
      let role1;
      if (role[0]) {
        role1 = role[0].roles[0].name;
        privileges1 = JSON.parse(role[0].privileges);
      } else {
        role1 = role.roles[0];
        privileges1 = JSON.parse(role.privileges);
      }

      isAuthorized = requiredRoles.includes(role1);

      // privileges1 = JSON.parse(role.privileges);
    }
    // console.log(role);

    //console.log(user);

    if (isAuthorized && privileges1[privileges]) {
      return children;
    } else {
      return <Redirect to="/invalid-access" />;
    }
  }
  return <Redirect to="/login" />;
};

export default ProtectedRoute;
